import { useEffect, useState } from "react"
import { useApp } from "../../../../context/app";
import { INews } from "../../../../interfaces/news";
import { parseDate } from "../../../../utils/date";

interface Props {
	news: INews
}

export function BottomNews({ news }: Props) {
	const { configDomainURL } = useApp()
	const domain = configDomainURL
	const [thumb, setThumb] = useState<string>('')

	useEffect(() => {

		if (!!news.img) {

			const thumbArray = news.img.split("/");
			const nome_imagem = thumbArray[thumbArray.length - 1];
			const thumb_url = 'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/tn/'
			setThumb(thumb_url + nome_imagem)

		}

	}, [])

	const link_montado = news.title
		.toLowerCase()
		.replaceAll(' ', '-')
		.replaceAll('/', '-')
		.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, "")

	//console.log('BottomNews.tsx ln 21 - link_montado')
	//console.log(link_montado)
	//console.log('news: ', news)
	//console.log('news.img: ', news.img)
	//console.log('thumb: ', thumb)

	return (

		<a
			href={`${domain}noticias/${news.newsId}/${link_montado}`}
			className="BottomNews"
		>
			<div className="BottomNews-wrapper">

				<div className="BottomNews-lente"></div>

				{

					!!news.img &&

					<div
						className="BottomNews-img"
						style={{
							backgroundImage: "url(" + thumb + ")",
						}}
					></div>

				}

				{/* COLUNA COM NOTICIA E DATA */}
				<div className={!!news.img ? "BottomNews-col" : "BottomNews-col BottomNews-noImg"} >

					<span>

						<div className="BottomNews-titulo-box">

							<div className="multi-line-ellipsis BottomNews-titulo">

								{news.title}

							</div>

						</div>

						<div className="BottomNews-data">

							{parseDate(news.published)}

						</div>

					</span>

				</div>

			</div>

		</a>

	)

}