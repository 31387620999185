import React from 'react';
import { useApp } from './context/app';
import { AppRoutes } from './routes';
import { ToolBar } from './components/ToolBar';
import { CookieBanner } from './components/CookieConsent/CookieConsent';
import {
    BrowserRouter as Router
} from "react-router-dom"

export function App() {
    const { basename } = useApp()
    return (
        <Router
            basename={basename}
        >
            <ToolBar />
            <AppRoutes />
            <CookieBanner />
        </Router>
    )
}