import CookieConsent from 'react-cookie-consent'
import './CookieConsent.css'

export function CookieBanner() {
	return (
		<CookieConsent
			enableDeclineButton
			location="bottom"
			buttonText="Aceitar"
			declineButtonText="Recusar"
			onDecline={() => console.log('Usuário recusou os cookies.')}
			style={{
				background: 'rgb(10, 10, 90)',
				color: '#fff',
				fontSize: '13px',
				padding: '12px',
				textAlign: 'center',
				display: 'block',
			}}
			buttonStyle={{
				background: 'rgb(255, 204, 0)',
				color: 'black',
				fontWeight: 'bold',
				fontSize: '13px',
				padding: '8px 16px',
				borderRadius: '5px',
			}}
			declineButtonStyle={{
				background: '#fff',
				color: 'black',
				fontWeight: 'bold',
				fontSize: '13px',
				padding: '8px 16px',
				borderRadius: '5px',
				margin: '14px',
			}}
		>
			Este site utiliza cookies para garantir a melhor experiência ao usuário.{' '}
			<br />
			Seguimos as diretrizes da{' '}
			<strong>LGPD (Lei Geral de Proteção de Dados)</strong>.
		</CookieConsent>
	)
}
