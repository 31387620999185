import { useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { TitlePage } from "../../components/TitlePage";
import { useEffect, useState } from "react";
import { api, urls } from "../../services/api";
import { INewsComplet } from "../../interfaces/news";
import { SubTitlePage } from "../../components/SubTitlePage";
import { ListImages } from "../../components/ListImages";
import { ListFiles } from "../../components/ListFiles";
import { MapSite } from "../../components/MapSite";
import { OtherNews } from "../../components/OtherNews";
import { useApp } from "../../context/app";
import { HeaderMobile } from "../../components/HeaderMobile";
import { SearchMobile } from "../../components/SearchMobile";
import { Footer } from "../../components/Footer";
import { NewsShare } from "../../components/NewsShare";
import VLibras from '@djpfs/react-vlibras';
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

export function NewsScreen() {

	let { id, title } = useParams();
	const { mobile, menuMobile, visible, configApiURL } = useApp()

	let pasta_tn = `${configApiURL}public/portaladm-pmitajai/noticias/tn/`;
	let pasta_foto = `${configApiURL}public/portaladm-pmitajai/noticias/foto/`;
	let pasta_original = `${configApiURL}public/portaladm-pmitajai/noticias/original/`;

	const [news, setNews] = useState<INewsComplet>(
		{
			content: '',
			description: '',
			photos: [],
			published: '',
			title: 'Carregando...',
			category: 0,
			gallery: [],
			subtitle: '',
			files: [],
			periodoEleitoral: '',
			download: '',
			photographer: '',
		}
	)

	const [content, setContent] = useState<string>()

	useEffect(() => {
		//Fancybox para imagem dentro do conteúdo
		Fancybox.bind('[data-fancybox]');

		(async () => {
			console.log("Noticia")
			try {

				const response = await api.get<INewsComplet>(urls.NewsById + id)

				//console.log('response.data', response.data)

				setNews(response.data)

				const replaceSpam = response.data.content.replace('<span style="color:black; font-family:var(--roboto); font-size:3vw">', '')
				setContent(replaceSpam.replace('</span>', ''))

			} catch (error) {
				console.log('pages-News: error', error)
				//window.location.href = domain;
			}

		})()

	}, [])

	//console.log('news: ', news)

	return (
		<div className="pages-News">

			{

				!news.periodoEleitoral &&

				<>

					{

						menuMobile ?

							(

								<HeaderMobile />

							)

							:

							(

								<Header
									search
									route={` ${news.title ? news.title : 'Não encontrado'}`}
								/>

							)

					}

					{

						mobile &&

						<SearchMobile />

					}

					{

						mobile &&

						<div className="pages-News-mobile-separador" />

					}

					<TitlePage title={news?.title.toUpperCase()} />

					<div className="pages-News-container">

						<div className="pages-News-subtitle">{news.subtitle}</div>

						<div className="pages-News-data">Data de inclusão: {news.published.replace("-", "")}</div>

						{

							!!news.photos[0] &&

							<a
								//href={`${pasta_original.split('portaladm-fmel/api/')[0]}public/portaladm-pmitajai/noticias/foto/${news.photos[0]}`}
								data-fancybox
								data-caption={"Foto: " + news?.photographer}
								data-src={pasta_foto + news.photos[0]}
							//className="ListImages-img-link"
							>

								<img
									src={pasta_foto + news.photos[0]}
									className="pages-News-img"
								/>

								{
									!!news.photographer &&

									<div className="pages-News-fotografo">Foto: {news?.photographer}</div>

								}

							</a>

						}

						<div
							className="pages-News-conteudo"
							dangerouslySetInnerHTML={{ __html: news.content }}
						/>

						{

							news.files.length > 0 &&

							<ListFiles files={news.files} />

						}

						{

							news.gallery.length > 0 &&

							<ListImages
								gallery={news.gallery}
								download={news.download}
							/>

						}

						<NewsShare />

						<OtherNews
							idNews={id || ''}
							categorieNewsId={news.category}
						/>

					</div>

					<MapSite />

					<Footer />

					{

						visible &&

						(

							<VLibras forceOnload />

						)

					}

				</>

			}

			{

				news.periodoEleitoral == 'Sim' &&

				<>

					{

						menuMobile ?

							(

								<HeaderMobile />

							)

							:

							(

								<Header search />

							)

					}

					{

						mobile &&

						<SearchMobile />

					}

					{

						mobile &&

						<div className="pages-News-mobile-separador" />

					}

					<TitlePage title="Período Eleitoral" />

					<div className="pages-News-container">

						<div
							className="pages-News-conteudo"
							style={{
								width: '100%',
							}}
						>
							<p>Em cumprimento à legislação eleitoral (Lei 9.504/1997), todas as publicações anteriores do Instagram da Prefeitura de Itajaí serão arquivadas e os comentários desabilitados de <strong>06 de julho a 06 de outubro de 2024.</strong> O Facebook, Youtube e Flickr serão desativados neste período.</p>
							<p>Em nosso site e no Instagram, serão publicadas apenas notícias de utilidade pública.</p>
							<p><strong>Mas fique tranquilo: </strong>todos os serviços do portal itajai.sc.gov.br continuarão funcionando normalmente.</p>
						</div>

					</div>

					<MapSite />

					<Footer />

					{

						visible &&

						(

							<VLibras forceOnload />

						)

					}

				</>

			}

		</div>

	)

}