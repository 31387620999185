import { useEffect } from 'react'
import { SubTitlePage } from "../SubTitlePage";
import { useApp } from "../../context/app";
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import { Fancybox } from '@fancyapps/ui'
//import { Fechar } from '../icons/common/fechar'
import './style.css';

interface Props {
	gallery: string[]
	download: string
}

export function ListImages({ gallery, download }: Props) {

	const { mobile } = useApp()

	// Função para gerar miniaturas das imagens
	const generateThumbnails = () => {
		return gallery.map((item) => {
			const imageName = item.split('/').pop()
			const baseUrl =
				'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/'
			return `${baseUrl}foto/${imageName}`
		})
	}

	useEffect(() => {
		// Inicializar o Fancybox depois que o componente for montado
		Fancybox.bind('[data-fancybox="gallery"]')
	}, [gallery]) // Quando a galeria mudar, inicializar novamente o Fancybox

	return (
		<div className="ListImages">
			<SubTitlePage title="Imagens Relacionadas" />

			<div className={mobile ? 'ListImages-mobile-itens' : 'ListImages-itens'}>
				{gallery.map((item, index) => {
					const imageName = item.split('/').pop()
					const baseUrl =
						'https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/noticias/'
					const thumb = `${baseUrl}foto/${imageName}`
					const imgOriginal = `${baseUrl}original/${imageName}`

					if (item === `${baseUrl}foto/.jpg`) return null

					return (
						<a
							key={imageName}
							data-fancybox="gallery"
							data-src={imgOriginal}
							className="ListImages-img-link"
						>
							<img
								src={imgOriginal}
								className="ListImages-img"
								alt="Imagem relacionada"
							/>
						</a>
					)
				})}
			</div>

			{!!download && (
				<a href={download} target="_blank" rel="noreferrer">
					<div className="ListImages-download">Fazer download das imagens</div>
				</a>
			)}
		</div>
	)

}