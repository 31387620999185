import { useEffect, useState } from "react"
import { useApp } from "../../context/app"
import { Item } from "./components/description"
import { Title } from "./components/title"
import { TitleInfo } from "./components/titleInfo"
import { api, urls } from "../../services/api"
import { IMenuItem } from "../../interfaces/menu"
import { FacebookIcon } from "../icons/social/facebook"
import { InstagramIcon } from "../icons/social/instagram"
import { YoutubeIcon } from "../icons/social/youtube"
import { FlickrIcon } from "../icons/social/flickr"
import './style.css'

type ItemMap = {
	title: string,
	url: string | null
}

export function MapSite() {

	const { mobile } = useApp()
	const [menu, setMenu] = useState<IMenuItem[]>([])

	useEffect(() => {
		(async () => {

			const response = await api.get(urls.menus)
			//console.log(response.data)

			setMenu([...response.data, {
				cod_menu_secao_grupo: 99999,
				titulo: 'Contato',
				menus: [
					{
						cod_menu: 0,
						link: 'localizacao',
						titulo: 'Localização'
					}, {
						cod_menu: 1,
						link: 'contato',
						titulo: 'Contato'
					},
				]
			}])

		})()
	}, [])

	if (menu.length < 1) {
		return <></>
	}

	const cidade: ItemMap[] = [
		{
			title: 'A Cidade',
			url: null
		},
		{
			title: 'Distâncias',
			url: null
		},
		{
			title: 'História',
			url: null
		},
		{
			title: 'Números',
			url: null
		},
		{
			title: 'Mapa',
			url: null
		},
		{
			title: 'Símbolos',
			url: null
		},
	]

	const gabinete: ItemMap[] = [
		{
			title: 'Prefeito',
			url: null
		},
		{
			title: 'Vice-Prefeito',
			url: null
		},
	]

	const autarquias: ItemMap[] = [
		{
			title: 'Itajaí Participações',
			url: null
		},
		{
			title: 'Instituto de Previdência de Itajaí (IPI)',
			url: null
		},
		{
			title: 'Porto de Itajaí',
			url: null
		},
		{
			title: 'SEMASA',
			url: null
		},
	]

	const secretarias: ItemMap[] = [
		{
			title: 'Agricultura e Expansão Urbana',
			url: null
		},
		{
			title: 'Administração e Gestão de Pessoas',
			url: null
		},
		{
			title: 'Assistência Social',
			url: null
		},
		{
			title: 'Comunicação',
			url: null
		},
		{
			title: 'Controladoria Geral',
			url: null
		},
		{
			title: 'Desenvolvimento Econômico',
			url: null
		},
		{
			title: 'Desenvolvimento Urbano e Habitação',
			url: null
		},
		{
			title: 'Educação',
			url: null
		},
		{
			title: 'Fazenda',
			url: null
		},
		{
			title: 'Governo',
			url: null
		},
		{
			title: 'Obras',
			url: null
		},
		{
			title: 'Procuradoria Geral',
			url: null
		},
		{
			title: 'Promoção da Cidadania',
			url: null
		},
		{
			title: 'Saúde',
			url: null
		},
		{
			title: 'Segurança Pública',
			url: null
		},
		{
			title: 'Tecnologia',
			url: null
		},
		{
			title: 'Turismo e Eventos',
			url: null
		},
	]

	const coodenadoria: ItemMap[] = [
		{
			title: 'Planejamento Estratégico do Município de Itajaí',
			url: null
		},
		{
			title: 'Geral de Contabilidade',
			url: null
		},
		{
			title: 'Assuntos Internacionais',
			url: null
		},
		{
			title: 'Coordenadoria de Proteção e Defesa Civil',
			url: null
		},
		{
			title: 'Centro de Atendimento ao Cidadão Cordeiros',
			url: null
		},
		{
			title: 'Centro de Atendimento ao Cidadão Santa Regina',
			url: null
		},
		{
			title: 'Centro de Atendimento ao Cidadão Limoeiro',
			url: null
		},
		{
			title: 'Centro de Atendimento ao Cidadão São Vicente',
			url: null
		},
		{
			title: 'Planejamento Estratégico do Município de Itajaí',
			url: null
		},
	]

	const fundacoes: ItemMap[] = [
		{
			title: 'Cultural',
			url: null
		},
		{
			title: 'Educação Profissional e Administração Pública (FEAPI)',
			url: null
		},
		{
			title: 'Esporte e Lazer',
			url: null
		},
		{
			title: 'Genésio Miranda Lins',
			url: null
		},
		{
			title: 'Instituto Itajaí Sustentável',
			url: null
		},
	]

	const servicos: ItemMap[] = []

	const imprensa: ItemMap[] = [
		{
			title: 'Notícias',
			url: null
		},
		{
			title: 'Banco de Imagens',
			url: null
		},
		{
			title: 'Vídeos',
			url: null
		},
	]

	return (

		<div className="MapSite">

			<div className="MapSite-container">

				<div className="MapSite-topo">

					<div className="MapSite-bloco">

						<TitleInfo>Horário de Atendimentos gerais nas Secretarias</TitleInfo>

						<div className="MapSite-txt">13h às 19h de segunda a sexta-feira</div>

					</div>

					<div className="MapSite-bloco">

						<TitleInfo>Horário de Atendimento na Praça do Cidadão</TitleInfo>

						<div className="MapSite-txt">08h às 19h de segunda a sexta-feira</div>

					</div>

					<div className="MapSite-bloco bloco-redes-sociais">

						<TitleInfo>Redes Sociais</TitleInfo>

						<div className="MapSite-txt">

							<a
								href="https://www.facebook.com/PrefeituradeItajai"
								target="_blank"
								className="MapSite-rede"
							><FacebookIcon /></a>

							<a
								href="https://www.instagram.com/prefeituradeitajai/"
								target="_blank"
								className="MapSite-rede"
							><InstagramIcon /></a>

							<a
								href="https://www.youtube.com/MunicipiodeItajai"
								target="_blank"
								className="MapSite-rede"
							><YoutubeIcon /></a>

							<a
								href="https://www.flickr.com/photos/secomitajai/sets/"
								target="_blank"
								className="MapSite-rede final"
							><FlickrIcon /></a>

						</div>

					</div>

					<div className="MapSite-bloco bloco-contato-infos">

						<TitleInfo>Contato</TitleInfo>

						<div className="MapSite-txt telefone">
							<a href="tel:+4733416000">(47) 3341-6000</a>
						</div>

					</div>

					<div className="MapSite-bloco bloco-selos">
						<img
							src={'../../assets/imgs/Selo_de_Transparencia.png'}
							alt={'Selo Transparência Pública Ouro 2024'}
						/>
					</div>

				</div>

				{/*
					
					!mobile &&
				
					<div className="MapSite-box">
					
						{
							menu && menu[0].secoes.map((itemSection) => (
							
								<div className="MapSite-bloco">
								
									<Title>{itemSection.titulo}</Title>
									
									{
										
										itemSection.menus && itemSection.menus.map((item) => (
										
											<Item key={item.cod_menu} menu={item} />
											
										))
										
									}
									
								</div>
								
							))
							
						}
						
					</div>
					
				*/}

			</div>

		</div >

	)

}